<template>
  <div>
    <b-container class="mt-5 mb-5">
      <h1 class="fs-4 font-weight-bold mb-4">Facturen</h1>
      <template v-if="!invoices.length">
        <b-card no-body>
          <b-card-body>
              <div class="text-center text-muted mt-4 mb-4">
                  <font-awesome-icon icon="file-invoice" class="mb-2" size="2x" />
                  <div class="text-dark fs-5">Geen facturen gevonden</div>
              </div>
          </b-card-body>
        </b-card>
      </template>
    </b-container>
  </div>
</template>

<script>
    export default {
        metaInfo: {
            title: 'Facturen',
        },
        components: {
        },
        data() {
            return {
              invoices: []
            }
        },
        computed: {},
        methods: {},
        created() {}
    }
</script>